<template>
  <div>
    <div class="bjffff"></div>
    <div class="content">
      <div class="contHead">
        <div class="boxs">
          <div
            class="box"
            :class="index == typeOn ? 'on' : ''"
            v-for="(item, index) in typeList"
            @click="typeClick(index)"
          >
            <div class="name">{{ item }}</div>
          </div>
        </div>
        <div class="inpBox">
          <input type="text" placeholder="请搜索您发布的内容" />
          <div class="ssBox iconfont icon-search"></div>
        </div>
      </div>
      <div class="contList">
        <Kong v-if="list.length==0"/>
        <div class="box" v-for="(item, index) in list">
          <div class="img">
            <img
              src="https://img1.baidu.com/it/u=3648625955,1664048131&fm=253&app=120&size=w931&n=0&f=JPEG&fmt=auto?sec=1719680400&t=4023fb5012bca5943ea13a0cf13e3981"
              alt=""
            />
          </div>
          <div class="contBox">
            <div class="name">{{ item.member.name }}</div>
            <div class="txt">1粉丝 0赞</div>
          </div>
          <div class="gzBox">
            <div
              class="gz"
              :class="item.member.is_favorite ? 'uns' : ''"
              @click="gzClick(item, index)"
            >
              {{ item.member.is_favorite ? "取消关注" : "关注" }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Kong from "../component/kong.vue";

export default {
  name: "",
  components: {
    Kong,
  },
  data() {
    return {
      typeOn: 0,
      typeList: ["我关注的", "关注我的"],
      judge: true,
      page: {
        pageNo: 1,
        pageSize: 20,
      },
      list: [],
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.type = this.$route.query.type;
    this.typeOn = this.$route.query.type;
    if (this.type == 0) {
      this.page.member_id = this.id;
    } else {
      this.page.favorite_id = this.id;
    }
    this.listGet();
  },
  methods: {
    listGet() {
      if (!this.judge) {
        return;
      }
      this.judge = false;
      this.$get("/web/favorite_member", this.page).then((res) => {
        console.log(res);
        this.judge = true;
        if (res.data) {
          for (let i = 0; i < res.data.length; i++) {
            const e = res.data[i];
            this.list.push(e);
          }
          this.page.pageNo++;
        } else {
          if (this.page.pageNo != 1) {
            this.$message.warning("已展示全部内容");
          }
        }
      });
    },
    gzClick(id,i) {
      this.$post("/web/favorite_save", {
        genre: 7,
        id,
      }).then((res) => {
        console.log(res);
        if (res.data.status) {
          this.list[i].member.is_favorite = res.data.data;
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    typeClick(i) {
      this.typeOn = i;
      if (i == 0) {
        this.page.member_id = this.id;
        this.page.favorite_id = "";
      } else {
        this.page.member_id = "";
        this.page.favorite_id = this.id;
      }
      this.page.pageNo = 1;
      this.list = [];
      this.listGet();
    },
    ltjump() {
      this.$router.push({
        path: "/wzCont",
        query: {},
      });
    },
  },
  mounted() {},
  computed: {},
};
</script>
<style lang="less" scoped>
.content {
  width: 1440px;
  margin: auto;
  box-sizing: border-box;
  overflow: hidden;
  background-color: #f5f4f4;
  box-sizing: border-box;
  padding: 15px;
  .leftBox {
    width: 194px;
    background-color: #fff;
    padding: 24px 0 20px 43px;
    margin-right: 10px;
    min-height: 504px;
    max-height: 90vh;
    border-radius: 6px;
    overflow-y: auto;
    float: left;
    border-radius: 5px;
    .li {
      height: 46px;
      cursor: pointer;
      line-height: 46px;
    }
    .on {
      color: #ee2223;
    }
  }
  .ritBox {
    float: left;
    width: calc(100% - 204px);
  }
}
.contHead {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 37px;
  background-color: #fff;
  border-radius: 5px;
  .boxs {
    display: flex;
    align-items: center;
    line-height: 60px;
  }
  .box {
    margin-right: 44px;
    height: 58px;
    cursor: pointer;

    .name {
      font-size: 16px;
    }
  }

  .on {
    border-bottom: 3px solid #f66;

    // transition: all .3s linear;
    .name {
      font-weight: 700;
    }
  }
  .inpBox {
    width: 220px;
    border-radius: 35px;
    height: 35px;
    border: 1px solid #f66;
    position: relative;
    color: #f66;
    overflow: hidden;
    margin-right: 15px;
    input {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding-left: 15px;
      color: #f66;
    }
    .ssBox {
      top: 0;
      right: 0;
      height: 100%;
      width: 30px;
      line-height: 35px;
      font-size: 18px;
      position: absolute;
      cursor: pointer;
    }
  }
}
.contList {
  border-radius: 5px;
  margin-top: 10px;
  background-color: #fff;
  padding: 20px;
  overflow: hidden;
  .box {
    float: left;
    width: calc(50% - 10px);
    margin-right: 20px;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 0 0 4px 2px #ebebeb;
    display: flex;
    align-items: center;
    .img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      overflow: hidden;
    }
    .contBox {
      height: 60px;
      width: calc(100% - 120px);
      padding-left: 15px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .name {
        font-size: 16px;
        font-weight: 600;
      }
      .txt {
        font-size: 12px;
        color: #9c9c9c;
      }
    }
    .gzBox {
      width: 60px;
      .gz {
        background-color: #ee2223;
        color: #fff;
        border-radius: 5px;
        text-align: center;
        font-size: 12px;
        width: 60px;
        height: 30px;
        cursor: pointer;
        line-height: 30px;
      }
      .uns {
        background-color: #9c9c9c;
      }
    }
  }
  .box:nth-child(2n) {
    margin-right: 0;
  }
}
</style>
